<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.versions.title"
      :progress-bar-loading="isLoading === 'versions'"
      :toolbar-buttons="toolbarButtons">

    <v-card
        elevation="2"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              {{ $t('pages.mods.versions.headers.name') }}
            </th>
            <th class="text-left">
              {{ $t('pages.mods.versions.headers.created') }}
            </th>
            <th class="text-left">
              {{ $t('pages.mods.versions.headers.branches') }}
            </th>
            <th class="text-left">
              {{ $t('pages.mods.versions.headers.takenSpace') }}
            </th>
            <th class="text-right">
              {{ $t('pages.mods.versions.headers.type') }}
            </th>
            <th class="text-right">
              {{ $t('pages.mods.versions.headers.actions') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-ripple
              v-for="(item, index) in versions"
              :key="index"
              @click.prevent="editVersion(item.id)"
              style="cursor: pointer;user-select: none"
          >
            <td>{{ item.name }}</td>
            <td>{{  dateTimeFormatter.format(new Date(item.creation)) }}</td>
            <td>{{ item.branches.map(b => b.name).join(', ') }}</td>
            <td>{{ formatSpace(item.takenSpace) }}</td>
            <td class="align-center">
              <v-row align="end">
                <v-col class="text-right">
                  <v-tooltip bottom v-if="item.quickJumpAvailability !== 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop
                          :disabled="item.quickJumpAvailability != 2"
                          small
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-debug-step-over
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.versions.type.quickJumps') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.everyFileAvailability !== 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop
                          :disabled="item.everyFileAvailability != 2"
                          small
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-file-multiple
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.versions.type.everyFile') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.fullPackageAvailability !== 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop
                          :disabled="item.fullPackageAvailability != 2"
                          small
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-package-variant-closed
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.versions.type.fullPackage') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.hashesAvailability !== 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop
                          :disabled="item.hashesAvailability != 2"
                          small
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-folder-pound
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.versions.type.hashes') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
            <td class="align-end">
              <v-row align="end">
                <v-col class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop="editVersion(item.id)"
                          :disabled="isLoading != null"
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-cog
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.versions.buttons.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop="deleteVersion(item.id)"
                          :disabled="isLoading != null"
                          :loading="isLoading === 'delete' && selected === item.id"
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.versions.buttons.delete') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import FormatService from "@/services/FormatService";
import {DialogService} from "@/services/DialogService";
import {ModsService} from "@/services/ModsService";

export default {
  name: "ModVersionsView",
  components: {SettingsPageLayout},
  data() {
    return {
      isLoading: null,
      selected: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: () => false,
          onClick: this.createVersion,
          icon: 'mdi-package-variant-closed',
          text: 'pages.mods.versions.toolbar.createVersion'
        },
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'versions',
          onClick: this.loadVersions,
          icon: 'mdi-refresh',
          text: 'pages.mods.versions.toolbar.refresh'
        }
      ],

      dateTimeFormatter: FormatService.dateTimeFormatter,

      versions: []
    }
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadVersions();
      }
    }
  },

  methods: {
    formatSpace: (space) => FormatService.formatSpace(space),
    loadVersions(){
      this.isLoading = 'versions'
      AlertService.clearAlert();
      ModsService.getVersions(this.$route.params.modId).then(versions => {
        this.$log.info('Loaded versions:',versions)
        this.versions = versions
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.versions.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    createVersion(){
      this.$router.push(`/mods/${this.$route.params.modId}/versions/create`);
    },
    editVersion(versionId){
      if(this.isLoading != null) return;

      this.$log.debug('Edit version:',versionId)

      this.$router.push(`/mods/${this.$route.params.modId}/versions/edit/${versionId}`)
    },
    deleteVersion(versionId){
      this.$log.debug('Delete version:',versionId)
      DialogService.addDialog({
        title: 'pages.mods.versions.deleteDialog.title',
        text: 'pages.mods.versions.deleteDialog.text',
        width: 400,
        buttons: [
          {
            text: 'pages.mods.versions.deleteDialog.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'pages.mods.versions.deleteDialog.buttons.delete',
            color: 'primary'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.selected = versionId;
        this.isLoading = 'delete';
        AlertService.clearAlert();
        ModsService.deleteVersion(this.$route.params.modId, versionId).then(res => {
          this.$router.push(`/progress/${res.progressId}`);
        }).catch(() => {
          AlertService.displayAlert('error', 'pages.mods.versions.messages.failedToDelete', 2000)
          this.isLoading = null
        })
      })
    }
  },

  mounted() {
    this.loadVersions();
  }
}
</script>

<style scoped>

</style>
