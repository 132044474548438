import Vue from "vue";
import {KeycloakService} from "@/services/KeycloakService";

/*const data1 = [
    {
        x: new Date('19 Jun 2021').getTime(),
        y: 0
    },
    {
        x: new Date('24 Jun 2021').getTime(),
        y: 10
    },
    {
        x: new Date('Jul 2021').getTime(),
        y: 20
    },
    {
        x: new Date('08 Jul 2021').getTime(),
        y: 50
    },
    {
        x: new Date('16 Jul 2021').getTime(),
        y: 15
    },
    {
        x: new Date('24 Jul 2021').getTime(),
        y: 5
    },
    {
        x: new Date('Aug 2021').getTime(),
        y: 20
    },
    {
        x: new Date('08 Aug 2021').getTime(),
        y: 30
    },
    {
        x: new Date('14 Aug 2021').getTime(),
        y: 25
    },
]

const data2 = [
    {
        x: new Date('19 Jun 2021').getTime(),
        y: 25
    },
    {
        x: new Date('24 Jun 2021').getTime(),
        y: 20
    },
    {
        x: new Date('Jul 2021').getTime(),
        y: 15
    },
    {
        x: new Date('08 Jul 2021').getTime(),
        y: 5
    },
    {
        x: new Date('16 Jul 2021').getTime(),
        y: 0
    },
    {
        x: new Date('24 Jul 2021').getTime(),
        y: 8
    },
    {
        x: new Date('Aug 2021').getTime(),
        y: 17
    },
    {
        x: new Date('08 Aug 2021').getTime(),
        y: 15
    },
    {
        x: new Date('14 Aug 2021').getTime(),
        y: 0
    },
]*/

export class LauncherService{

    static getVersions(){
        return new Promise((resolve, reject) => {
            Vue.axios.get('launcher/versions', KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getVersion(versionId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`launcher/versions/${versionId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }


    static setVersion(versionId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`launcher/versions/${versionId}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createVersion(data){
        return new Promise((resolve, reject) => {
            Vue.axios.post('launcher/versions', data, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteVersion(versionId){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`launcher/versions/${versionId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getBranches(){
        return new Promise((resolve, reject) => {
            Vue.axios.get('launcher/branches', KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getBranch(branchId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`launcher/branches/${branchId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setBranch(branchId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`launcher/branches/${branchId}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createBranch(data){
        return new Promise((resolve, reject) => {
            Vue.axios.post('launcher/branches', data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteBranch(branchId){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`launcher/branches/${branchId}`, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createMilestone(data){
        return new Promise((resolve, reject) => {
            Vue.axios.post('launcher/statistics/milestones/', data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setMilestone(id, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`launcher/statistics/milestones/${id}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteMilestone(id){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`launcher/statistics/milestones/${id}`, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static loadStatistics(){
        return new Promise((resolve, reject) => {
            Vue.axios.get('launcher/statistics', KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
        /*return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    milestones: [
                        {
                            id: '1',
                            name: 'Milestone 1',
                            date: new Date('16 Jul 2021').getTime()
                        },
                        {
                            id: '2',
                            name: 'Milestone 2',
                            date: new Date('Jul 2021').getTime()
                        },
                    ],
                    statistics: [{
                        name: 'Data1',
                        data: data1
                    },{
                        name: 'Data2',
                        data: data2
                    }]
                })
            }, 0)
        })*/
    }
}
