<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.settings.title"
      :progress-bar-loading="isLoading === 'settings'"
      :toolbar-buttons="toolbarButtons">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-model="directoryName"
          :rules="directoryNameRules"
          :label="$t('pages.mods.settings.fields.directoryName.label')"
          :hint="$t('pages.mods.settings.fields.directoryName.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-folder-outline"
          required
      ></v-text-field>
      <v-text-field
          v-model="projectName"
          :rules="projectNameRules"
          :label="$t('pages.mods.settings.fields.projectName.label')"
          :hint="$t('pages.mods.settings.fields.projectName.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-card class="mt-2">
        <v-card-title>
          <span style="flex-basis: 100%">{{$t('pages.mods.settings.fields.filesToIgnore.header')}}</span>
          <v-text-field
              v-model="fileToIgnore"
              @keyup.enter="addFileToIgnore"
              :label="$t('pages.mods.settings.fields.fileToIgnore.label')"
              :disabled="isLoading != null"
              single-line
              hide-details
          >
            <template v-slot:append-outer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      :disabled="isLoading != null || canAdd === false"
                      @click="addFileToIgnore"
                      v-on="on"
                      v-bind="attrs">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.mods.settings.fields.fileToIgnore.addButton') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('pages.mods.settings.fields.filesToIgnore.headers.file') }}
                </th>
                <th class="text-right">
                  {{ $t('pages.mods.settings.fields.filesToIgnore.headers.actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                  v-for="(item, index) in filesToIgnore"
                  :key="index"
              >
                <td>{{ item }}</td>
                <td class="align-end">
                  <v-row align="end">
                    <v-col class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              icon
                              @click="deleteFileToIgnore(index)"
                              :disabled="isLoading != null"
                              v-on="on"
                              v-bind="attrs">
                            <v-icon
                                small>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>{{ $t('pages.mods.settings.fields.filesToIgnore.buttons.delete') }}</span>
                      </v-tooltip>

                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t('pages.mods.settings.buttons.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";
export default {
  name: "ModSettingsView",
  components: {SettingsPageLayout},
  data(){
    return {
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'settings',
          onClick: this.loadSettings,
          icon: 'mdi-refresh',
          text: 'pages.mods.settings.toolbar.refresh'
        }
      ],

      valid: true,

      directoryName: '',
      directoryNameRules: [
        v => !!v || this.$t('pages.mods.settings.fields.directoryName.errors.required')
      ],
      projectName: '',
      projectNameRules: [
        v => !!v || this.$t('pages.mods.settings.fields.projectName.errors.required')
      ],
      fileToIgnore: '',
      filesToIgnore: []
    }
  },

  computed: {
    canAdd(){
      return this.fileToIgnore && this.filesToIgnore.indexOf(this.fileToIgnore) < 0;
    }
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadSettings();
      }
    }
  },

  methods: {
    loadSettings(){
      this.isLoading = 'settings'
      AlertService.clearAlert();
      ModsService.getSettings(this.$route.params.modId).then(settings => {
        this.$log.info('Loaded settings:',settings)
        this.directoryName = settings.directoryName;
        this.projectName = settings.projectName;
        this.baseMod = settings.baseMod;
        this.filesToIgnore = settings.filesToIgnore;
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.settings.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();
      ModsService.setSettings(this.$route.params.modId, {
        directoryName: this.directoryName,
        projectName: this.projectName,
        baseMod: this.baseMod,
        filesToIgnore: this.filesToIgnore
      }).then(() => {
        this.$log.info('Saved settings')
        AlertService.displayAlert('success', 'pages.mods.settings.messages.saved', 2000)
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.settings.messages.failedToSave', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    addFileToIgnore(){
      if(!this.canAdd) return;

      this.filesToIgnore.push(this.fileToIgnore);
      this.fileToIgnore = '';
    },
    deleteFileToIgnore(index){
      this.filesToIgnore.splice(index, 1)
    }
  },

  mounted() {
    this.loadSettings();
  }
}
</script>

<style scoped>

</style>
