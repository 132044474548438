<template>
  <settings-page-layout
      :is-loading="isLoading"
      :toolbar-title="isEdit ? 'pages.mods.button.title.edit' : 'pages.mods.button.title.create'"
      :progress-bar-loading="isLoading === 'button'"
      :toolbar-buttons="isEdit ? toolbarButtons : []">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-model="linkUrl"
          :rules="linkUrlRules"
          :label="$t('pages.mods.button.fields.linkUrl.label')"
          :hint="$t('pages.mods.button.fields.linkUrl.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-link-variant"
          required
      ></v-text-field>
      <v-text-field
          v-model="imageUrl"
          :rules="imageUrlRules"
          :label="$t('pages.mods.button.fields.imageUrl.label')"
          :hint="$t('pages.mods.button.fields.imageUrl.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-image-outline"
          required
      ></v-text-field>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              class="mr-2"
              :disabled="isLoading != null"
              color="secondary"
              @click="cancel">
            {{ $t('pages.mods.button.buttons.cancel') }}
          </v-btn>
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t(isEdit ? 'pages.mods.button.buttons.save' : 'pages.mods.button.buttons.create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";

export default {
  name: "ModButtonView",
  components: {SettingsPageLayout},
  data(){
    return{
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'button',
          onClick: this.loadButton,
          icon: 'mdi-refresh',
          text: 'pages.mods.button.toolbar.refresh'
        }
      ],

      valid: true,

      linkUrl: '',
      linkUrlRules: [
        v => !!v || this.$t('pages.mods.button.fields.linkUrl.errors.required')
      ],
      imageUrl: '',
      imageUrlRules: [
        v => !!v || this.$t('pages.mods.button.fields.imageUrl.errors.required')
      ]
    }
  },

  computed: {
    isEdit(){
      return this.$route.params.buttonId != null;
    }
  },

  methods: {
    loadButton(){
      if(!this.isEdit) return

      this.isLoading = 'button'
      AlertService.clearAlert();
      ModsService.getButton(this.$route.params.modId, this.$route.params.buttonId).then(button => {
        this.$log.info('Loaded button:',button)
        this.linkUrl = button.linkUrl;
        this.imageUrl = button.imageUrl;
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.button.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();

      const data = {
        linkUrl: this.linkUrl,
        imageUrl: this.imageUrl
      }

      const promise = this.isEdit
          ? ModsService.setButton(this.$route.params.modId, this.$route.params.buttonId, data)
          : ModsService.createButton(this.$route.params.modId, data);

      promise.then(() => {
        if(this.isEdit) {
          this.$log.info('Saved data')
          AlertService.displayAlert('success', 'pages.mods.button.messages.saved', 2000)
        }else{
          this.$log.info('Created button')
          this.cancel();
        }
      }).catch(() => {
        if(this.isEdit) {
          AlertService.displayAlert('error', 'pages.mods.button.messages.failedToSave', 0)
        }else{
          AlertService.displayAlert('error', 'pages.mods.button.messages.failedToCreate', 0)
        }
      }).finally(() => {
        this.isLoading = null;
      })
    },
    cancel(){
      this.$router.push(`/mods/${this.$route.params.modId}/buttons`)
    }
  },

  mounted() {
    this.loadButton();
  }
}
</script>

<style scoped>

</style>