<template>
  <settings-page-layout
    :is-loading="isLoading"
    toolbar-title="pages.progressOverview.title"
    :progress-bar-loading="isLoading === 'progresses'"
    :toolbar-buttons="toolbarButtons">
    <v-card
        elevation="2"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              {{ $t('pages.progressOverview.headers.state') }}
            </th>
            <th class="text-left" style="width:50%">
              {{ $t('pages.progressOverview.headers.name') }}
            </th>
            <th class="text-left">
              {{ $t('pages.progressOverview.headers.started') }}
            </th>
            <th class="text-left">
              {{ $t('pages.progressOverview.headers.finished') }}
            </th>
            <th class="text-right">
              {{ $t('pages.progressOverview.headers.actions') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-ripple
              v-for="(item, index) in progresses"
              :key="index"
              @click.prevent="viewProgress(item)"
              style="cursor: pointer;user-select: none"
          >
            <td><v-icon :color="getColor(item)">{{ getIcon(item) }}</v-icon></td>
            <td>{{ item.name }}</td>
            <td>{{  dateTimeFormatter.format(new Date(item.started)) }}</td>
            <td>{{ (item.finished != null ? dateTimeFormatter.format(new Date(item.finished)) : '...') }}</td>
            <td class="align-end">
              <v-row align="end">
                <v-col class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop="viewProgress(item)"
                          :disabled="isLoading != null"
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-eye-arrow-right
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.progressOverview.buttons.view') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop="deleteProgress(item.id)"
                          :disabled="isLoading != null || item.finished == null"
                          :loading="isLoading === 'delete' && selected === item.id"
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.progressOverview.buttons.delete') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </settings-page-layout>
</template>

<script>
import {ProgressService} from "@/services/ProgressService";
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import FormatService from "@/services/FormatService";
import {WebSocketService} from "@/services/WebSocketService";
import {DialogService} from "@/services/DialogService";

export default {
  name: "ProgressOverviewView",
  components: {SettingsPageLayout},
  data(){
    return{
      isLoading: null,
      selected: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'progresses',
          onClick: this.loadProgresses,
          icon: 'mdi-refresh',
          text: 'pages.progressOverview.toolbar.refresh'
        }
      ],

      dateTimeFormatter: FormatService.dateTimeFormatter,

      progresses: []
    }
  },

  methods: {
    loadProgresses(){
      this.isLoading = 'progresses'
      AlertService.clearAlert();
      ProgressService.getProgresses().then(progresses => {
        this.$log.info('Loaded progresses:',progresses)
        this.progresses = progresses
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.progressOverview.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    viewProgress(progress){
      if(this.isLoading != null) return;

      this.$log.debug('View progress:',progress.id)
      this.$router.push(`/progress/${progress.id}`)
    },
    deleteProgress(id){
      this.$log.debug('Delete progress:',id)
      DialogService.addDialog({
        title: 'pages.progressOverview.deleteDialog.title',
        text: 'pages.progressOverview.deleteDialog.text',
        width: 400,
        buttons: [
          {
            text: 'pages.progressOverview.deleteDialog.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'pages.progressOverview.deleteDialog.buttons.delete',
            color: 'primary'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.selected = id;
        this.isLoading = 'delete';
        AlertService.clearAlert();
        ProgressService.deleteProgress(id).then(() => {
          this.loadProgresses()
        }).catch(() => {
          AlertService.displayAlert('error', 'pages.progressOverview.messages.failedToDelete', 2000)
          this.isLoading = null
        })
      })
    },
    getColor(item){
      return ProgressService.getIconAndColorFromState(item.state).color;
    },
    getIcon(item){
      return ProgressService.getIconAndColorFromState(item.state).icon;
    }
  },

  mounted() {
    this.loadProgresses()
    WebSocketService.connection.on('progress-overview-changed', this.loadProgresses)
  },

  beforeDestroy() {
    WebSocketService.connection.off('progress-overview-changed', this.loadProgresses)
  }
}
</script>

<style scoped>

</style>