import Vue from "vue";
import {KeycloakService} from "@/services/KeycloakService";

const STATES = [
    {
        // Waiting/Unknown
        color: 'secondary',
        icon: 'mdi-progress-question'
    },
    {
        // Running
        color: null,
        icon: 'mdi-progress-clock'
    },
    {
        // Warning
        color: 'warning',
        icon: 'mdi-progress-alert'
    },
    {
        // Failed
        color: 'error',
        icon: 'mdi-progress-close'
    },
    {
        // Success
        color: 'green',
        icon: 'mdi-progress-check'
    }
]

export class ProgressService {

    static getProgresses(){
        return new Promise((resolve, reject) => {
            Vue.axios.get('progress', KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getProgress(progressId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`progress/${progressId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteProgress(progressId){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`progress/${progressId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getIconAndColorFromState(state){
        if(state >= 0 && state < STATES.length)
            return STATES[state];
        else
            return STATES[0];
    }
}