// initial state
const state = () => ({
    mods: null
})

// getters
const getters = {
    getMods: (state) => {
        return state.mods;
    }
}

// actions
const actions = {

}

// mutations
const mutations = {
    setMods(state, mods){
        state.mods = mods
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
