import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLogger from 'vuejs-logger';
import VueAxios from "vue-axios";
import axios from "axios";
import vuetify from './plugins/vuetify'
import {KeycloakService} from "@/services/KeycloakService";
import {UserService} from "@/services/UserService";
import i18n from './i18n'
import EventBus from "@/utils/EventBus";
import {WebSocketService} from "@/services/WebSocketService";
import VueApexCharts from 'vue-apexcharts'

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  showConsoleColors: true
};

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '';
Vue.use(VueLogger, options);
Vue.use(VueAxios, axios);
Vue.prototype.$eventBus = EventBus

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

KeycloakService.initKeycloak().then(() => {
  UserService.getMyRoles().then(() => {
    WebSocketService.setup();

    Vue.$log.info("Fetched roles:", UserService.roles)

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
})
