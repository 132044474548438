import Vue from "vue";
import {KeycloakService} from "@/services/KeycloakService";

const adminRole = "Admin"
//const modderRole = "Modder"

export class UserService{
    static roles = []

    static getMyRoles(){
        return new Promise((resolve, reject) => {
            Vue.axios.get('users/@me/roles', KeycloakService.getAxiosConfig()).then(res => {
                this.roles = res.data;
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getUserId(){
        return KeycloakService.KeycloakInstance.tokenParsed.sub;
    }

    static isAdmin(){
        return this.roles.includes(adminRole);
    }

    static findUsers(query){
        return new Promise((resolve, reject) => {
            Vue.axios.post('users/search', {
                query
            }, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getUsernames(ids){
        return new Promise((resolve, reject) => {
            Vue.axios.post('users/usernames', {
                userIds: ids
            }, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }
}
