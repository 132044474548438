<template>
  <settings-page-layout
      :is-loading="isLoading"
      :toolbar-title="isEdit ? 'pages.mods.version.title.edit' : 'pages.mods.version.title.create'"
      :progress-bar-loading="isLoading === 'version'"
      :toolbar-buttons="isEdit ? toolbarButtons : []">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-if="isEdit"
          :value="$route.params.versionId"
          :label="$t('pages.mods.version.fields.id.label')"
          :hint="$t('pages.mods.version.fields.id.hint')"
          :disabled="isLoading != null"
          readonly
          prepend-icon="mdi-identifier"
          required
      ></v-text-field>
      <v-text-field
          v-model="name"
          :rules="nameRules"
          :label="$t('pages.mods.version.fields.name.label')"
          :hint="$t('pages.mods.version.fields.name.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-select
          v-model="branches"
          :items="availableBranches"
          :disabled="isLoading != null || loadingBranches != null"
          :loading="loadingBranches === 'loading'"
          menu-props="auto"
          :label="$t('pages.mods.version.fields.branches.label')"
          item-value="id"
          item-text="name"
          hide-details
          prepend-icon="mdi-source-branch"
          multiple
          :no-data-text="$t('pages.mods.version.fields.branches.noData')"
      >
        <template v-slot:append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  :disabled="isLoading != null || loadingBranches != null"
                  :loading="loadingBranches === 'loading'"
                  @click="loadBranches"
                  v-on="on"
                  v-bind="attrs">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>

            <span>{{ $t('pages.mods.version.buttons.reloadBranches') }}</span>
          </v-tooltip>
        </template>
      </v-select>

      <v-card class="mt-2">
        <v-card-title>
          <span style="flex-basis: 100%"><v-icon dense class="mr-2">mdi-flag</v-icon>{{$t('pages.mods.version.fields.versionFlags.header')}}</span>
        </v-card-title>
        <v-card-text>
          <v-checkbox
              v-model="forceClearCache"
              :label="$t('pages.mods.version.fields.versionFlags.forceClearCache.label')"
              :disabled="isLoading != null"
              hide-details
          ></v-checkbox>
        </v-card-text>
      </v-card>
      <template v-if="!isEdit">
        <v-card class="mt-4">
          <v-card-title>
            <span style="flex-basis: 100%"><v-icon dense class="mr-2">mdi-wrench</v-icon>{{$t('pages.mods.version.fields.buildSettings.header')}}</span>
          </v-card-title>
          <v-card-text>
            <v-checkbox
                v-model="pullScript"
                :label="$t('pages.mods.version.fields.buildSettings.executePullScript.label')"
                :disabled="isLoading != null"
                hide-details
            ></v-checkbox>

            <v-checkbox
                v-model="quickJumps"
                :label="$t('pages.mods.version.fields.buildSettings.quickJumps.label.create')"
                :disabled="isLoading != null"
                hide-details
            ></v-checkbox>

            <v-checkbox
                v-model="everyFile"
                :label="$t('pages.mods.version.fields.buildSettings.everyFile.label.create')"
                :disabled="isLoading != null"
                hide-details
            ></v-checkbox>

            <v-checkbox
                v-model="fullPackage"
                :label="$t('pages.mods.version.fields.buildSettings.fullPackage.label.create')"
                :disabled="isLoading != null"
                hide-details
            ></v-checkbox>

            <v-checkbox
                v-model="hashes"
                :label="$t('pages.mods.version.fields.buildSettings.hashes.label.create')"
                :disabled="isLoading != null"
                hide-details
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </template>
      <template v-if="isEdit">
        <v-card class="mt-4">
          <v-card-title>
            <span style="flex-basis: 100%"><v-icon dense class="mr-2">mdi-wrench</v-icon>{{$t('pages.mods.version.fields.buildSettings.header')}}</span>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="">
                    <v-row>
                      <v-checkbox
                          v-model="quickJumps"
                          :label="$t('pages.mods.version.fields.buildSettings.quickJumps.label.display')"
                          style="margin-top: 0"
                          readonly
                          hide-details
                      ></v-checkbox>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-title>
                    <v-autocomplete
                        v-if="canCreateQuickJumps"
                        auto-select-first
                        clearable
                        v-model="versionInput"
                        :label="$t('pages.mods.version.fields.buildSettings.availableQuickJumps.versionInput.label')"
                        :loading="loadingVersions === 'loading'"
                        :disabled="isLoading != null || loadingVersions != null"
                        item-text="name"
                        item-value="id"
                        :items="remainingVersions"
                        single-line
                        hide-details
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                :disabled="isLoading != null || loadingVersions != null || versionInput == null || availableQuickJumps.indexOf(versionInput) >= 0"
                                :loading="isLoading === 'quickJump'"
                                @click="createQuickJump(versionInput)"
                                v-on="on"
                                v-bind="attrs">
                              <v-icon>mdi-package-variant-closed</v-icon>
                            </v-btn>
                          </template>

                          <span>{{ $t('pages.mods.version.fields.buildSettings.availableQuickJumps.versionInput.buttons.create') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                :disabled="isLoading != null || loadingVersions != null"
                                @click="loadVersions"
                                v-on="on"
                                v-bind="attrs">
                              <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                          </template>

                          <span>{{ $t('pages.mods.version.fields.buildSettings.availableQuickJumps.versionInput.buttons.refresh') }}</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                    <span class="error--text" v-else>{{$t('pages.mods.version.fields.buildSettings.availableQuickJumps.versionInput.errors.requirements')}}</span>
                  </v-card-title>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t('pages.mods.settings.fields.filesToIgnore.headers.file') }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(item, index) in availableQuickJumps"
                          :key="index"
                      >
                        <td>{{versionName(item)}} ({{ item }})</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-checkbox
                v-model="everyFile"
                :label="$t('pages.mods.version.fields.buildSettings.everyFile.label.display')"
                readonly
                hide-details
            ></v-checkbox>

            <v-checkbox
                v-model="fullPackage"
                :label="$t('pages.mods.version.fields.buildSettings.fullPackage.label.display')"
                readonly
                hide-details
            ></v-checkbox>

            <v-checkbox
                v-model="hashes"
                :label="$t('pages.mods.version.fields.buildSettings.hashes.label.display')"
                readonly
                hide-details
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </template>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              class="mr-2"
              :disabled="isLoading != null"
              color="secondary"
              @click="cancel">
            {{ $t('pages.mods.version.buttons.cancel') }}
          </v-btn>
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t(isEdit ? 'pages.mods.version.buttons.save' : 'pages.mods.version.buttons.create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";
import FormatService from "@/services/FormatService";

export default {
  name: "ModVersionView",
  components: {SettingsPageLayout},
  data(){
    return{
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'version',
          onClick: this.loadVersion,
          icon: 'mdi-refresh',
          text: 'pages.launcher.version.toolbar.refresh'
        }
      ],

      loadingBranches: null,
      availableBranches: [],
      loadingVersions: null,
      availableVersions: [],

      valid: true,

      name: 'Test '+FormatService.getVersionNameTime(),
      nameRules: [
        v => !!v || this.$t('pages.mods.version.fields.name.errors.required')
      ],
      branches: [],
      availableQuickJumps: [],

      pullScript: true,
      quickJumps: true,
      fullPackage: false,
      everyFile: false,
      hashes: true,
      forceClearCache: true,

      versionInput: null
    }
  },

  computed: {
    isEdit(){
      return this.$route.params.versionId != null;
    },
    versionName() {
      return versionId => {
        const version = this.availableVersions.find(v => v.id === versionId)

        if (version == null)
          return "..."

        return version.name
      }
    },
    remainingVersions(){
      if(this.availableQuickJumps == null)
        return [];

      return this.availableVersions.filter(v => v.id != this.$route.params.modId && this.availableQuickJumps.indexOf(v.id) < 0)
    },
    canCreateQuickJumps(){
      return this.everyFile;
    }
  },

  methods: {
    loadVersion(){
      this.loadBranches();
      if(this.isEdit){
        this.loadVersions();
      }else{
        return;
      }

      this.isLoading = 'version'
      AlertService.clearAlert();
      ModsService.getVersion(this.$route.params.modId, this.$route.params.versionId).then(version => {
        this.$log.info('Loaded version:',version)
        this.id = version.id
        this.name = version.name
        this.branches = version.branches.map(b => b.id)
        this.quickJumps = version.quickJumpAvailability === 2;
        this.fullPackage = version.fullPackageAvailability === 2;
        this.everyFile = version.everyFileAvailability === 2;
        this.hashes = version.hashesAvailability === 2;
        this.forceClearCache = version.forceClearCache;
        this.availableQuickJumps = version.availableQuickJumps != null ? version.availableQuickJumps : []
        this.loadBranches();
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.version.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    loadBranches(){
      this.loadingBranches = 'loading'
      AlertService.clearAlert();
      ModsService.getBranches(this.$route.params.modId).then(branches => {
        this.$log.info('Loaded branches:',branches)
        this.availableBranches = branches
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.version.messages.failedToLoadBranches', 2000)
      }).finally(() => {
        this.loadingBranches = null;
      })
    },
    loadVersions(){
      this.loadingVersions = 'loading'
      AlertService.clearAlert();
      ModsService.getVersions(this.$route.params.modId).then(versions => {
        this.$log.info('Loaded available versions:',versions)
        this.availableVersions = versions
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.version.messages.failedToLoadAvailableVersions', 2000)
      }).finally(() => {
        this.loadingVersions = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();

      const promise = this.isEdit
          ? ModsService.setVersion(this.$route.params.modId, this.$route.params.versionId, {
            name: this.name,
            branches: this.branches,
            forceClearCache: this.forceClearCache
          })
          : ModsService.createVersion(this.$route.params.modId, {
            name: this.name,
            branches: this.branches,
            executePullScript: this.pullScript,
            createQuickJumps: this.quickJumps,
            createFullPackage: this.fullPackage,
            createEveryFile: this.everyFile,
            createHashes: this.hashes,
            forceClearCache: this.forceClearCache
          });

      promise.then(res => {
        if(this.isEdit) {
          this.$log.info('Saved data')
          AlertService.displayAlert('success', 'pages.mods.version.messages.saved', 2000)
        }else{
          this.$log.info('Created version. Progress at:',res.progressId)
          this.$router.push(`/progress/${res.progressId}`);
        }
      }).catch(err => {
        if(this.isEdit) {
          if(err.response != null && err.response.data != null && err.response.data.errorText != null){
            AlertService.displayAlert('error', `errors.${err.response.data.errorText}`, 0)
          }else{
            AlertService.displayAlert('error', 'pages.mods.version.messages.failedToSave', 0)
          }
        }else{
          AlertService.displayAlert('error', 'pages.mods.version.messages.failedToCreate', 0)
        }
      }).finally(() => {
        this.isLoading = null;
      })
    },
    createQuickJump(versionId){
      this.$log.info("Create quick jump for",versionId)
      this.isLoading = 'quickJump';
      ModsService.createVersionQuickJump(this.$route.params.modId, this.$route.params.versionId, versionId).then(res => {
        this.$log.info('Creating quick-jump. Progress at:',res.progressId)
        this.$router.push(`/progress/${res.progressId}`);
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.version.messages.failedToCreateQuickJump', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    cancel(){
      this.$router.push(`/mods/${this.$route.params.modId}/versions`)
    }
  },

  mounted() {
    this.loadVersion();
  }
}
</script>

<style scoped>

</style>