import EventBus from "@/utils/EventBus";

export const DisplayAlertEvent = "DISPLAY_ALERT";
export const ClearAlertEvent = "CLEAR_ALERT";

export class AlertService {

    static displayAlert(type, message, timeout = 5000){
        EventBus.$emit(DisplayAlertEvent, {
            type,
            message,
            timeout
        });
    }

    static clearAlert(){
        EventBus.$emit(ClearAlertEvent);
    }
}