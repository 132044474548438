<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        ref="drawer"
        app
        clipped
    >
      <v-list>
        <v-list-group prepend-icon="mdi-folder-download-outline" v-if="isAdmin === true">
          <template v-slot:activator>
            <v-list-item-title>{{$t('pages.launcher.title')}}</v-list-item-title>
          </template>
          <v-list-item
              to="/launcher/versions"
              link>
            <v-list-item-icon>
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('pages.launcher.versions.title') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/launcher/images"
              link>
            <v-list-item-icon>
              <v-icon>mdi-image-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('pages.launcher.images.title') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/launcher/branches"
              link>
            <v-list-item-icon>
              <v-icon>mdi-source-branch</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('pages.launcher.branches.title') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/launcher/statistics"
              link>
            <v-list-item-icon>
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('pages.launcher.statistics.title') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-group>
        <template v-if="mods.state === 'loading'">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-progress-circular
                  size="18"
                  width="3"
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('components.mainLayout.texts.loadingMods')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <template v-if="mods.state === 'error'">
          <v-divider></v-divider>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="error--text text-center">{{$t('components.mainLayout.texts.loadingModsFailed')}}</v-list-item-title>
              <v-list-item-subtitle class="text-center"><v-btn text small @click="loadMods">{{$t('components.mainLayout.texts.retryLoadMods')}}</v-btn></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <template v-for="(mod, index) in mods.data">
          <v-list-group v-if="mods.state === 'loaded'" prepend-icon="mdi-package-variant-closed" :key="index">
            <template v-slot:activator>
              <v-list-item-title>{{ mod.name }}</v-list-item-title>
            </template>
            <v-list-item
                :to="`/mods/${mod.id}/versions`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-cloud-download-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.versions.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="`/mods/${mod.id}/images`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-image-search-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.images.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="`/mods/${mod.id}/buttons`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-gesture-tap-button</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.buttons.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="`/mods/${mod.id}/branches`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-source-branch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.branches.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="`/mods/${mod.id}/statistics`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-chart-line</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.statistics.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="`/mods/${mod.id}/settings`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.settings.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="isAdmin === true"
                :to="`/mods/${mod.id}/admin`"
                link>
              <v-list-item-icon>
                <v-icon>mdi-shield-crown-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('pages.mods.admin.title') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-group>
        </template>
        <v-list-item
            v-if="isAdmin === true"
            to="/mods/create"
            link>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('pages.mods.create.title') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            to="/progress"
            link>
          <v-list-item-icon>
            <v-icon>mdi-progress-question</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('pages.progressOverview.title') }}
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
            @click="logout"
            link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('components.mainLayout.texts.logout') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        app
        clipped-left
        color="primary"
        dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ $t('title') }}</span>
        <v-tooltip v-if="isConnected !== true" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2"
            >
              mdi-connection
            </v-icon>
          </template>
          <span>{{ $t('texts.noConnection') }}</span>
        </v-tooltip>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-card style="height: 100%">
        <router-view></router-view>
      </v-card>
    </v-main>

    <dialog-manager></dialog-manager>
  </v-app>
</template>

<script>
import {UserService} from "@/services/UserService";
import {ModsService} from "@/services/ModsService";
import {KeycloakService} from "@/services/KeycloakService";
import {mapGetters, mapMutations} from "vuex";
import DialogManager from "@/components/DialogManager";

export default {
  name: "MainLayout",
  components: {DialogManager},
  data(){
    return {
      drawer: null,
      mods: {
        state: 'loading',
        data: []
      }
    }
  },

  computed: {
    isAdmin(){
      return UserService.isAdmin();
    },
    ...mapGetters('websocketModule', ['isConnected'])
  },

  methods: {
    ...mapMutations("modsModule", ["setMods"]),
    loadMods(){
      this.mods.state = 'loading';
      ModsService.getMods().then(mods => {
        this.$log.info("Loaded mods:",mods);
        this.mods.data = mods;
        this.mods.state = 'loaded';

        this.setMods(mods)

      }).catch(() => {
        this.mods.state = 'error';
      })
    },
    logout(){
      this.$log.info('Logging out...');
      KeycloakService.KeycloakInstance.logout()
    },
    onModsUpdated(){
      this.loadMods();
    }
  },

  mounted() {
    this.loadMods();
    this.$on('mods-updated', this.onModsUpdated)
  },

  beforeDestroy() {
    this.$off('mods-updated', this.onModsUpdated)
  }
}
</script>

<style scoped>

</style>