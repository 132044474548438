<template>
  <settings-page-layout
      :is-loading="isLoading"
      :toolbar-title="isEdit ? 'pages.launcher.branch.title.edit' : 'pages.launcher.branch.title.create'"
      :progress-bar-loading="isLoading === 'branch'"
      :toolbar-buttons="isEdit ? toolbarButtons : []">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-model="name"
          :rules="nameRules"
          :label="$t('pages.launcher.branch.fields.name.label')"
          :hint="$t('pages.launcher.branch.fields.name.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-checkbox
          v-model="isPublic"
          :label="$t('pages.launcher.branch.fields.isPublic.label')"
      ></v-checkbox>
      <user-input-list
          v-model="authorizedUsers"
          list-title="pages.launcher.branch.fields.authorizedUsers.header"
          :disabled="isLoading != null || isPublic == true"
          class="mt-2"></user-input-list>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              class="mr-2"
              :disabled="isLoading != null"
              color="secondary"
              @click="cancel">
            {{ $t('pages.launcher.branch.buttons.cancel') }}
          </v-btn>
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t(isEdit ? 'pages.launcher.branch.buttons.save' : 'pages.launcher.branch.buttons.create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {LauncherService} from "@/services/LauncherService";
import UserInputList from "@/components/UserInputList";

export default {
  name: "LauncherBranchView",
  components: {UserInputList, SettingsPageLayout},
  data(){
    return{
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'branch',
          onClick: this.loadBranch,
          icon: 'mdi-refresh',
          text: 'pages.launcher.branch.toolbar.refresh'
        }
      ],

      valid: true,

      name: '',
      nameRules: [
        v => !!v || this.$t('pages.launcher.branch.fields.name.errors.required')
      ],
      isPublic: false,
      authorizedUsers: []
    }
  },

  computed: {
    isEdit(){
      return this.$route.params.branchId != null;
    }
  },

  methods: {
    loadBranch(){
      if(!this.isEdit) return

      this.isLoading = 'branch'
      AlertService.clearAlert();
      LauncherService.getBranch(this.$route.params.branchId).then(branch => {
        this.$log.info('Loaded branch:',branch)
        this.name = branch.name
        this.isPublic = branch.isPublic
        this.authorizedUsers = branch.authorizedUserIds
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.launcher.branch.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();

      const data = {
        name: this.name,
        isPublic: this.isPublic,
        authorizedUserIds: this.authorizedUsers
      }

      const promise = this.isEdit
          ? LauncherService.setBranch(this.$route.params.branchId, data)
          : LauncherService.createBranch(data);

      promise.then(() => {
        if(this.isEdit) {
          this.$log.info('Saved data')
          AlertService.displayAlert('success', 'pages.launcher.branch.messages.saved', 2000)
        }else{
          this.$log.info('Created branch')
          this.cancel();
        }
      }).catch(() => {
        if(this.isEdit) {
          AlertService.displayAlert('error', 'pages.launcher.branch.messages.failedToSave', 0)
        }else{
          AlertService.displayAlert('error', 'pages.launcher.branch.messages.failedToCreate', 0)
        }
      }).finally(() => {
        this.isLoading = null;
      })
    },
    cancel(){
      this.$router.push('/launcher/branches')
    }
  },

  mounted() {
    this.loadBranch();
  }
}
</script>

<style scoped>

</style>