var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('settings-page-layout',{attrs:{"is-loading":_vm.isLoading,"toolbar-title":"pages.mods.versions.title","progress-bar-loading":_vm.isLoading === 'versions',"toolbar-buttons":_vm.toolbarButtons}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.mods.versions.headers.name'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.mods.versions.headers.created'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.mods.versions.headers.branches'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.mods.versions.headers.takenSpace'))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('pages.mods.versions.headers.type'))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('pages.mods.versions.headers.actions'))+" ")])])]),_c('tbody',_vm._l((_vm.versions),function(item,index){return _c('tr',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,staticStyle:{"cursor":"pointer","user-select":"none"},on:{"click":function($event){$event.preventDefault();return _vm.editVersion(item.id)}}},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm.dateTimeFormatter.format(new Date(item.creation))))]),_c('td',[_vm._v(_vm._s(item.branches.map(function (b) { return b.name; }).join(', ')))]),_c('td',[_vm._v(_vm._s(_vm.formatSpace(item.takenSpace)))]),_c('td',{staticClass:"align-center"},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"text-right"},[(item.quickJumpAvailability !== 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.quickJumpAvailability != 2,"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-debug-step-over ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.mods.versions.type.quickJumps')))])]):_vm._e(),(item.everyFileAvailability !== 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.everyFileAvailability != 2,"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.mods.versions.type.everyFile')))])]):_vm._e(),(item.fullPackageAvailability !== 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.fullPackageAvailability != 2,"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-package-variant-closed ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.mods.versions.type.fullPackage')))])]):_vm._e(),(item.hashesAvailability !== 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.hashesAvailability != 2,"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-folder-pound ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.mods.versions.type.hashes')))])]):_vm._e()],1)],1)],1),_c('td',{staticClass:"align-end"},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null},on:{"click":function($event){$event.stopPropagation();return _vm.editVersion(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.mods.versions.buttons.edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'delete' && _vm.selected === item.id},on:{"click":function($event){$event.stopPropagation();return _vm.deleteVersion(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.mods.versions.buttons.delete')))])])],1)],1)],1)])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }