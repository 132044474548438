<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.admin.title"
      :progress-bar-loading="isLoading === 'data'"
      :toolbar-buttons="toolbarButtons">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          :value="$route.params.modId"
          :label="$t('pages.mods.admin.fields.id.label')"
          :hint="$t('pages.mods.admin.fields.id.hint')"
          :disabled="isLoading != null"
          readonly
          prepend-icon="mdi-identifier"
          required
      ></v-text-field>
      <v-text-field
          v-model="name"
          :rules="nameRules"
          :label="$t('pages.mods.admin.fields.name.label')"
          :hint="$t('pages.mods.admin.fields.name.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-checkbox
          v-model="alwaysShow"
          :label="$t('pages.mods.admin.fields.alwaysShow.label')"
      ></v-checkbox>
      <v-select
        v-model="baseMod"
        :rules="baseModRules"
        :label="$t('pages.mods.admin.fields.baseMod.label')"
        :hint="$t('pages.mods.admin.fields.baseMod.hint')"
        :items="getMods !== null ? getMods : []"
        :loading="getMods === null"
        :disabled="getMods === null || isLoading != null"
        :prepend-icon="baseMod !== null ? baseMod !== $route.params.modId ? 'mdi-package-variant-closed-check' : 'mdi-package-variant-closed-remove' : 'mdi-package-variant'"
        clearable
        item-text="name"
        item-value="id"
      ></v-select>
      <user-input-list
          v-model="authorizedUsers"
          list-title="pages.mods.admin.fields.authorizedUsers.header"
          :disabled="isLoading != null"
          class="mt-2"></user-input-list>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t('pages.mods.admin.buttons.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";
import UserInputList from "@/components/UserInputList";
import {mapGetters} from "vuex";

export default {
  name: "ModAdminView",
  components: {UserInputList, SettingsPageLayout},
  data(){
    return {
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'data',
          onClick: this.loadData,
          icon: 'mdi-refresh',
          text: 'pages.mods.admin.toolbar.refresh'
        }
      ],

      valid: true,

      name: '',
      nameRules: [
        v => !!v || this.$t('pages.mods.admin.fields.name.errors.required')
      ],
      baseMod: null,
      baseModRules: [
        v => v !== this.$route.params.modId || this.$t('pages.mods.admin.fields.baseMod.errors.sameMod')
      ],
      alwaysShow: false,
      authorizedUsers: []
    }
  },

  computed: {
    ...mapGetters("modsModule", ["getMods"])
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadData();
      }
    }
  },

  methods: {
    loadData(){
      this.isLoading = 'data'
      AlertService.clearAlert();
      ModsService.getModData(this.$route.params.modId).then(data => {
        this.$log.info('Loaded data:',data)
        this.name = data.name
        this.alwaysShow = data.alwaysShow
        this.baseMod = data.baseModId
        this.authorizedUsers = data.authorizedUserIds
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.admin.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();
      ModsService.setModData(this.$route.params.modId, {
        name: this.name,
        alwaysShow: this.alwaysShow,
        baseModId: this.baseMod,
        authorizedUserIds: this.authorizedUsers
      }).then(() => {
        this.$log.info('Saved data')
        AlertService.displayAlert('success', 'pages.mods.admin.messages.saved', 2000)
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.admin.messages.failedToSave', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    }
  },

  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
