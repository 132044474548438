<template>
  <div>
    <v-dialog
        v-for="(item, index) in dialogs"
        v-model="item.show"
        :key="index"
        :width="item.width"
        :persistent="item.persistent"
        @input="dialogHidden(index)"
    >
      <v-card>
        <v-card-title>
          {{ $t(item.title) }}
        </v-card-title>

        <v-card-text>
          {{ $t(item.text) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-for="(btn, btnIndex) in item.buttons" :key="`btn_${btnIndex}`"
              :color="btn.color"
              text
              @click="clicked(item, btnIndex)"
          >
            {{ $t(btn.text) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "DialogManager",

  data(){
    return {
      dialog: true
    }
  },

  computed: {
    ...mapGetters('dialogModule', ['dialogs'])
  },

  methods: {
    ...mapMutations('dialogModule', ['removeDialog']),
    dialogHidden(index){
      setTimeout(() => {
        this.$log.debug('Dialog hidden:',index);
        this.removeDialog(index)
      },200)
    },
    clicked(item, btnIndex){
      item.resolve(btnIndex)
      item.show = false
    }
  }
}
</script>

<style scoped>

</style>