<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.launcher.images.title"
      :progress-bar-loading="isLoading === 'images'"
      :toolbar-buttons="toolbarButtons">

  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";

export default {
  name: "LauncherImagesView",
  components: {SettingsPageLayout},
  data() {
    return {
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'images',
          onClick: this.loadImages,
          icon: 'mdi-refresh',
          text: 'pages.launcher.images.toolbar.refresh'
        }
      ]
    }
  },

  methods: {
    loadImages(){

    }
  },

  mounted() {
    this.loadImages();
  }
}
</script>

<style scoped>

</style>