// initial state
const state = () => ({
    isConnected: false
})

// getters
const getters = {
    isConnected: (state) => {
        return state.isConnected === true;
    }
}

// actions
const actions = {

}

// mutations
const mutations = {
    setConnectionState(state, isConnected){
        state.isConnected = isConnected === true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
