<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.create.title"
      :progress-bar-loading="isLoading === 'create'"
      :toolbar-buttons="[]">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-model="modName"
          :rules="modNameRules"
          :label="$t('pages.mods.create.fields.modName.label')"
          :hint="$t('pages.mods.create.fields.modName.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'create'"
              color="success"
              @click="create">
            {{ $t('pages.mods.create.buttons.create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";

export default {
  name: "ModCreateView",
  components: {SettingsPageLayout},
  data() {
    return {
      isLoading: null,

      valid: true,

      modName: null,
      modNameRules: [
        v => !!v || this.$t('pages.mods.create.fields.modName.errors.required')
      ]
    }
  },

  methods: {
    create(){
      this.isLoading = 'create';
      AlertService.clearAlert();

      ModsService.createMod({
        name: this.modName
      }).then(data => {
        AlertService.displayAlert('success', 'pages.mods.create.messages.created', 2000)
        this.$router.push(`/mods/${data.id}/settings`)
        this.$emit('mods-updated')
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.create.messages.failedToCreate', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    }
  }
}
</script>

<style scoped>

</style>