<template>
  <v-card v-bind="$attrs">
    <v-card-title>
      <span style="flex-basis: 100%">{{$t(listTitle)}}</span>
      <v-autocomplete
          v-model="userInput"
          :items="availableUsers"
          :disabled="disabled"
          :loading="fetchingUsers"
          :search-input.sync="userQuery"
          hide-no-data
          hide-selected
          item-text="userName"
          item-value="id"
          :label="$t('components.userInputList.fields.userQuery.label')"
          :placeholder="$t('components.userInputList.fields.userQuery.placeholder')"
          prepend-icon="mdi-account-search"
          return-object
          @keyup.enter="addUser"
      >
        <template v-slot:append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  :disabled="disabled || canAdd === false"
                  @click="addUser"
                  v-on="on"
                  v-bind="attrs">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <span>{{ $t('components.userInputList.buttons.add') }}</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            {{ $t('components.userInputList.headers.userName') }}
          </th>
          <th class="text-right">
            {{ $t('components.userInputList.headers.actions') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in users"
            :key="index"
        >
          <td>{{ getUserName(item) }}</td>
          <td class="align-end">
            <v-row align="end">
              <v-col class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        @click="deleteUser(index)"
                        :disabled="disabled"
                        v-on="on"
                        v-bind="attrs">
                      <v-icon
                          small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('components.userInputList.buttons.delete') }}</span>
                </v-tooltip>

              </v-col>
            </v-row>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import {UserService} from "@/services/UserService";
import _ from "lodash";
import {AlertService} from "@/services/AlertService";

export default {
  name: "UserInputList",

  props: [
      'value',
      'listTitle',
      'disabled'
  ],

  data(){
    return {
      userQuery: '',
      lastSearch: null,
      availableUsers: [],
      userInput: null,
      users: [],
      fetchingUsers: false,

      knownUserNames: []
    }
  },

  computed: {
    canAdd(){
      if(this.userInput == null) return false;
      if(this.users.includes(this.userInput.id)) return false;

      return true;
    }
  },

  watch: {
    userQuery(){
      if(this.isLoading != null) return;

      this.searchUsers();
    },
    value(){
      if(this.users != this.value){
        this.users = this.value;
        this.loadUnknownUserNames();
      }
    },
    users(){
      if(this.users != this.value){
        this.$emit('input', this.users);
      }
    }
  },

  methods: {
    loadUnknownUserNames(){
      var unknownUsers = this.users.filter(id => this.knownUserNames[id] == null);

      this.$log.debug('UnknownUsers:',unknownUsers)

      if(unknownUsers.length > 0) {
        UserService.getUsernames(unknownUsers).then(usernames => {
          Object.keys(usernames).forEach(k => {
            this.knownUserNames[k] = usernames[k];
            this.registerUserName(k, usernames[k])
          })
          this.$log.debug('KnownUsers:',this.knownUserNames)
          this.$forceUpdate()
        }).catch(() => {
          AlertService.displayAlert('error', 'components.userInputList.messages.failedToLoadUserNames', 2000)
        })
      }
    },
    registerUserName(userId, userName){
      if(this.knownUserNames.some(k => k.id === userId)) return;

      this.knownUserNames.push({
        id: userId,
        userName: userName
      })
    },
    getUserName(userId){
      let index = this.knownUserNames.findIndex(k => k.id === userId);

      if(index < 0) return '...';

      return this.knownUserNames[index].userName;
    },
    searchUsers: _.debounce(
        function searchUsers(){
          const search = this.userQuery;

          if(search == this.lastSearch) return;
          if(this.userInput != null && search == this.userInput.userName) return;

          this.$log.debug('UserInput is:',this.userInput)

          this.fetchingUsers = true
          UserService.findUsers(search || '').then(users => {
            this.$log.debug('Fetched users:',users);
            users.forEach(u => {
              this.registerUserName(u.id, u.userName)
            })
            this.availableUsers = users;
            this.lastSearch = search;
          }).finally(() => {
            this.fetchingUsers = false;
          })
        }, 500),
    addUser(){
      if(!this.canAdd) return;

      this.users.push(this.userInput.id);
      this.userQuery = ''
      this.userInput = null
    },
    deleteUser(index){
      this.users.splice(index, 1);
    },
  },

  mounted() {
    this.users = this.value;
    this.loadUnknownUserNames();
  }
}
</script>

<style scoped>

</style>