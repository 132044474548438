import Vue from "vue";
import {KeycloakService} from "@/services/KeycloakService";

export class ModsService{
    static getMods(){
        return new Promise((resolve, reject) => {
            Vue.axios.get('mods', KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createMod(modData){
        return new Promise((resolve, reject) => {
            Vue.axios.post('mods/create', modData, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }
    
    static getVersions(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/versions`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getVersion(modId, versionId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/versions/${versionId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setVersion(modId, versionId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/versions/${versionId}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createVersion(modId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/versions`, data, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createVersionQuickJump(modId, targetVersion, sourceVersion){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/versions/${targetVersion}/quick-jump/${sourceVersion}`, {}, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteVersion(modId, versionId){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`mods/${modId}/versions/${versionId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getSettings(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/settings`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setSettings(modId, settings){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/settings`, settings, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getImages(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/images`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setImages(modId, images){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/images`, images, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getModData(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setModData(modId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}`, data, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getBranches(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/branches`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getBranch(modId, branchId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/branches/${branchId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setBranch(modId, branchId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/branches/${branchId}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createBranch(modId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/branches`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteBranch(modId, branchId){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`mods/${modId}/branches/${branchId}`, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getButtons(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/buttons`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static getButton(modId, buttonId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/buttons/${buttonId}`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setButton(modId, buttonId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/buttons/${buttonId}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createButton(modId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/buttons`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteButton(modId, buttonId){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`mods/${modId}/buttons/${buttonId}`, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static createMilestone(modId, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/statistics/milestones/`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static setMilestone(modId, id, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`mods/${modId}/statistics/milestones/${id}`, data, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static deleteMilestone(modId, id){
        return new Promise((resolve, reject) => {
            Vue.axios.delete(`mods/${modId}/statistics/milestones/${id}`, KeycloakService.getAxiosConfig()).then(() => {
                return resolve();
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static loadStatistics(modId){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`mods/${modId}/statistics`, KeycloakService.getAxiosConfig()).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }
}
