<template>
  <settings-page-layout
      :is-loading="isLoading"
      :translate-toolbar-title="false"
      :progress-bar-loading="isLoading === 'progress'"
      :toolbar-buttons="toolbarButtons">
    <template v-slot:toolbar-title>
      <v-toolbar-title><v-icon :color="getColor(progress)" class="mr-2">{{getIcon(progress)}}</v-icon>{{ $t('pages.progress.title', {
        progressName: progress.name != null ? progress.name : '...'
      }) }}</v-toolbar-title>
    </template>
    <v-expansion-panels multiple v-if="progress != null">
      <v-expansion-panel v-for="(item, index) in progress.progresses" :key="index">
        <v-expansion-panel-header disable-icon-rotate>
          {{ item.name }}
          <template v-slot:actions>
            <v-icon :color="getColor(item)">
              {{ getIcon(item) }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea multi-line readonly outlined rows="16" class="mt-2" label="Log" :value="logs[item.id] || ' '"></v-textarea>
          <v-progress-linear v-for="(progress, progressIndex) in item.progresses" :key="`progress_${progressIndex}`" :value="progress * 100"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ProgressService} from "@/services/ProgressService";
import {WebSocketService} from "@/services/WebSocketService";

export default {
  name: "ProgressView",
  components: {SettingsPageLayout},
  data(){
    return{
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'progress',
          onClick: this.loadProgress,
          icon: 'mdi-refresh',
          text: 'pages.progress.toolbar.refresh'
        }
      ],

      progress: {
        name: null,
        progresses: [],
        state: 0
      },
      logs: new Object()
    }
  },

  methods: {
    loadProgress(){
      this.isLoading = 'progress'
      AlertService.clearAlert();
      ProgressService.getProgress(this.$route.params.progressId).then(progress => {
        this.$log.info('Loaded progress:',progress)

        progress.progresses.forEach(p => {
          this.logs[p.id] = p.log
          delete p['log'];
        })

        this.progress.name = progress.name
        this.progress.progresses = progress.progresses
        this.progress.state = progress.state
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.progress.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    progressChanged(progressId, progressData){
      if(progressId !== this.$route.params.progressId) return;

      this.$log.debug('Progress changed:',progressId, progressData)
      this.progress.name = progressData.name
      this.progress.progresses = progressData.progresses
      this.progress.state = progressData.state
    },
    progressLog(progressContainerId, progressId, log){
      if(progressContainerId !== this.$route.params.progressId) return;

      this.$log.debug('Log received:',progressId, log)
      this.logs[progressId] += `${log}\n`;
      this.$forceUpdate()
    },
    getColor(item){
      return ProgressService.getIconAndColorFromState(item.state).color;
    },
    getIcon(item){
      return ProgressService.getIconAndColorFromState(item.state).icon;
    }
  },

  mounted() {
    this.loadProgress()
    WebSocketService.connection.on('progress-changed', this.progressChanged)
    WebSocketService.connection.on('progress-log', this.progressLog)
  },

  beforeDestroy() {
    WebSocketService.connection.off('progress-changed', this.progressChanged)
    WebSocketService.connection.off('progress-log', this.progressLog)
  }
}
</script>

<style scoped>

</style>