import * as signalR from "@microsoft/signalr";
import Vue from "vue";
import {KeycloakService} from "@/services/KeycloakService";
import store from '@/store'

export class WebSocketService{
    static connection = new signalR.HubConnectionBuilder()
        .withUrl((process.env.VUE_APP_API_URL || "")+"hubs/main", { accessTokenFactory() {
                return KeycloakService.KeycloakInstance.token
            }})
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds() {
                return 2500
            }
        })
        .build();


    static #setConnectedState(state){
        store.commit('websocketModule/setConnectionState', state)
    }

    static setup(){
        this.connection.onclose(() => {
            Vue.$log.info('WebSocket connection was closed!');
            this.#setConnectedState(false);
        })
        this.connection.onreconnecting(() => {
            Vue.$log.info('WebSocket connection is trying to reconnect...');
            this.#setConnectedState(false);
        })
        this.connection.onreconnected(() => {
            Vue.$log.info('WebSocket connection was reconnected!');
            this.#setConnectedState(true);
        })

        this.connection.start().then(() => {
            this.#setConnectedState(true);
        })
    }
}