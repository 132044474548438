<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.images.title"
      :progress-bar-loading="isLoading === 'images'"
      :toolbar-buttons="toolbarButtons">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-model="iconUrl"
          :rules="iconUrlRules"
          :label="$t('pages.mods.images.fields.iconUrl.label')"
          :hint="$t('pages.mods.images.fields.iconUrl.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-folder-outline"
          required
      ></v-text-field>
      <v-text-field
          v-model="backgroundUrl"
          :rules="backgroundUrlRules"
          :label="$t('pages.mods.images.fields.backgroundUrl.label')"
          :hint="$t('pages.mods.images.fields.backgroundUrl.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-card class="mt-2">
        <v-card-title>
          <span style="flex-basis: 100%">{{$t('pages.mods.images.fields.loadingImages.header')}}</span>
          <v-text-field
              v-model="loadingImageInput"
              @keyup.enter="addLoadingImage"
              :label="$t('pages.mods.images.fields.urlInput.label')"
              :disabled="isLoading != null"
              single-line
              hide-details
          >
            <template v-slot:append-outer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      :disabled="isLoading != null || canAdd === false"
                      @click="addLoadingImage"
                      v-on="on"
                      v-bind="attrs">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.mods.images.fields.loadingImages.addButton') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                {{ $t('pages.mods.images.fields.loadingImages.headers.url') }}
              </th>
              <th class="text-right">
                {{ $t('pages.mods.images.fields.loadingImages.headers.actions') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in loadingImages"
                :key="index"
            >
              <td>{{ item }}</td>
              <td class="align-end">
                <v-row align="end">
                  <v-col class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="deleteLoadingImage(index)"
                            :disabled="isLoading != null"
                            v-on="on"
                            v-bind="attrs">
                          <v-icon
                              small>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('pages.mods.images.fields.loadingImages.buttons.delete') }}</span>
                    </v-tooltip>

                  </v-col>
                </v-row>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t('pages.mods.images.buttons.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";

export default {
  name: "ModImagesView",
  components: {SettingsPageLayout},
  data() {
    return {
      isLoading: null,

      valid: true,

      iconUrl: null,
      iconUrlRules: [],
      backgroundUrl: null,
      backgroundUrlRules: [],
      loadingImageInput: '',
      loadingImages: [],

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'images',
          onClick: this.loadImages,
          icon: 'mdi-refresh',
          text: 'pages.mods.images.toolbar.refresh'
        }
      ]
    }
  },

  computed: {
    canAdd(){
      return this.loadingImageInput && this.loadingImages.indexOf(this.loadingImageInput) < 0;
    }
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadImages();
      }
    }
  },

  methods: {
    loadImages(){
      this.isLoading = 'images'
      AlertService.clearAlert();
      ModsService.getImages(this.$route.params.modId).then(images => {
        this.$log.info('Loaded images:',images)

        this.iconUrl = images.iconUrl;
        this.backgroundUrl = images.backgroundUrl;
        this.loadingImages = images.loadingImages || [];
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.images.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();

      ModsService.setImages(this.$route.params.modId, {
        iconUrl: this.iconUrl,
        backgroundUrl: this.backgroundUrl,
        loadingImages: this.loadingImages
      }).then(() => {
        AlertService.displayAlert('success', 'pages.mods.images.messages.saved', 2000)
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.images.messages.failedToSave', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    addLoadingImage(){
      console.log('Add: ',this.loadingImageInput)
      console.log('CanAdd: ',this.canAdd)
      if(!this.canAdd) return;

      this.loadingImages.push(this.loadingImageInput);
      this.loadingImageInput = '';
    },
    deleteLoadingImage(index){
      this.loadingImages.splice(index, 1)
    }
  },

  mounted() {
    this.loadImages();
  }
}
</script>

<style scoped>

</style>
