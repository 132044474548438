<template>
  <settings-page-layout
      :is-loading="isLoading"
      :toolbar-title="isEdit ? 'pages.launcher.version.title.edit' : 'pages.launcher.version.title.create'"
      :progress-bar-loading="isLoading === 'version'"
      :toolbar-buttons="isEdit ? toolbarButtons : []">
    <v-form
        ref="form"
        v-model="valid">
      <v-text-field
          v-if="isEdit"
          :value="$route.params.versionId"
          :label="$t('pages.launcher.version.fields.id.label')"
          :hint="$t('pages.launcher.version.fields.id.hint')"
          :disabled="isLoading != null"
          readonly
          prepend-icon="mdi-identifier"
          required
      ></v-text-field>
      <v-text-field
          v-model="name"
          :rules="nameRules"
          :label="$t('pages.launcher.version.fields.name.label')"
          :hint="$t('pages.launcher.version.fields.name.hint')"
          :disabled="isLoading != null"
          prepend-icon="mdi-form-textbox"
          required
      ></v-text-field>
      <v-select
          v-model="branches"
          :items="availableBranches"
          :disabled="isLoading != null || loadingBranches != null"
          :loading="loadingBranches === 'loading'"
          menu-props="auto"
          :label="$t('pages.launcher.version.fields.branches.label')"
          item-value="id"
          item-text="name"
          hide-details
          prepend-icon="mdi-source-branch"
          multiple
          :no-data-text="$t('pages.launcher.version.fields.branches.noData')"
      >
        <template v-slot:append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  :disabled="isLoading != null || loadingBranches != null"
                  :loading="loadingBranches === 'loading'"
                  @click="loadBranches"
                  v-on="on"
                  v-bind="attrs">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>

            <span>{{ $t('pages.launcher.version.buttons.reloadBranches') }}</span>
          </v-tooltip>
        </template>
      </v-select>
      <v-row justify="end" class="mt-2">
        <v-col class="text-right">
          <v-btn
              class="mr-2"
              :disabled="isLoading != null"
              color="secondary"
              @click="cancel">
            {{ $t('pages.launcher.version.buttons.cancel') }}
          </v-btn>
          <v-btn
              :disabled="!valid || isLoading != null"
              :loading="isLoading === 'save'"
              color="success"
              @click="save">
            {{ $t(isEdit ? 'pages.launcher.version.buttons.save' : 'pages.launcher.version.buttons.create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {LauncherService} from "@/services/LauncherService";

export default {
  name: "LauncherVersionView",
  components: {SettingsPageLayout},
  data(){
    return{
      isLoading: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'version',
          onClick: this.loadVersion,
          icon: 'mdi-refresh',
          text: 'pages.launcher.version.toolbar.refresh'
        }
      ],

      loadingBranches: null,
      availableBranches: [],

      valid: true,

      name: '',
      nameRules: [
        v => !!v || this.$t('pages.launcher.version.fields.name.errors.required')
      ],
      branches: [],
    }
  },

  computed: {
    isEdit(){
      return this.$route.params.versionId != null;
    }
  },

  methods: {
    loadVersion(){
      if(!this.isEdit) {
        this.loadBranches();
        return
      }

      this.isLoading = 'version'
      AlertService.clearAlert();
      LauncherService.getVersion(this.$route.params.versionId).then(version => {
        this.$log.info('Loaded version:',version)
        this.id = version.id
        this.name = version.name
        this.branches = version.branches.map(b => b.id)
        this.loadBranches();
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.launcher.version.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    loadBranches(){
      this.loadingBranches = 'loading'
      AlertService.clearAlert();
      LauncherService.getBranches().then(branches => {
        this.$log.info('Loaded branches:',branches)
        this.availableBranches = branches
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.launcher.version.messages.failedToLoadBranches', 2000)
      }).finally(() => {
        this.loadingBranches = null;
      })
    },
    save(){
      this.isLoading = 'save'
      AlertService.clearAlert();

      const data = {
        name: this.name,
        branches: this.branches
      }

      const promise = this.isEdit
          ? LauncherService.setVersion(this.$route.params.versionId, data)
          : LauncherService.createVersion(data);

      promise.then(res => {
        if(this.isEdit) {
          this.$log.info('Saved data')
          AlertService.displayAlert('success', 'pages.launcher.version.messages.saved', 2000)
        }else{
          this.$log.info('Created version. Progress at:',res.progressId)
          this.$router.push(`/progress/${res.progressId}`);
        }
      }).catch(err => {
        if(this.isEdit) {
          if(err.response != null && err.response.data != null && err.response.data.errorText != null){
            AlertService.displayAlert('error', `errors.${err.response.data.errorText}`, 0)
          }else{
            AlertService.displayAlert('error', 'pages.launcher.version.messages.failedToSave', 0)
          }
        }else{
          AlertService.displayAlert('error', 'pages.launcher.version.messages.failedToCreate', 0)
        }
      }).finally(() => {
        this.isLoading = null;
      })
    },
    cancel(){
      this.$router.push('/launcher/versions')
    }
  },

  mounted() {
    this.loadVersion();
  }
}
</script>

<style scoped>

</style>