<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.statistics.title"
      :progress-bar-loading="isLoading === 'statistics'"
      :toolbar-buttons="toolbarButtons">
    <v-card
        elevation="2">
      <v-card-title>
        {{ $t('pages.mods.statistics.milestones.title') }}
        <v-progress-circular
            v-if="isLoading === 'statistics'"
            class="ml-3"
            :size="24"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="isLoading != 'statistics'">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left" style="width:50%">
                {{ $t('pages.mods.statistics.milestones.headers.name') }}
              </th>
              <th class="text-left">
                {{ $t('pages.mods.statistics.milestones.headers.date') }}
              </th>
              <th class="text-right">
                {{ $t('pages.mods.statistics.milestones.headers.actions') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in milestones"
                :key="index"
            >
              <td>{{ item.name }}</td>
              <td>{{ dateTimeFormatter.format(item.date) }}</td>
              <td class="align-end">
                <v-row align="end">
                  <v-col class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="editMilestone(item)"
                            :disabled="isLoading != null"
                            v-on="on"
                            v-bind="attrs">
                          <v-icon
                              small>
                            mdi-cog
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('pages.mods.statistics.milestones.buttons.edit') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="deleteMilestone(item)"
                            :disabled="isLoading != null"
                            :loading="isLoading === 'delete' && selected == item"
                            v-on="on"
                            v-bind="attrs">
                          <v-icon
                              small>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('pages.mods.statistics.milestones.buttons.delete') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>


    <v-card
        elevation="2"
        class="mt-5">
      <v-card-title>
        {{ $t('pages.mods.statistics.headings.updates.all') }}
        <v-progress-circular
            v-if="isLoading === 'statistics'"
            class="ml-3"
            :size="24"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="isLoading != 'statistics'">
        <div id="wrapper">
          <div id="chart-line2">
            <apexchart type="line" height="230" :options="allUpdatesChartOptions" :series="allUpdatesSeries"></apexchart>
          </div>
          <div id="chart-line">
            <apexchart type="area" height="130" :options="allUpdatesChartOptionsLine" :series="allUpdatesSeries"></apexchart>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
        elevation="2"
        class="mt-5">
      <v-card-title>
        {{ $t('pages.mods.statistics.headings.updates.errorChecks') }}
        <v-progress-circular
            v-if="isLoading === 'statistics'"
            class="ml-3"
            :size="24"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="isLoading != 'statistics'">
        <div id="wrapper">
          <div id="chart-line2">
            <apexchart type="line" height="230" :options="errorChecksChartOptions" :series="errorChecksSeries"></apexchart>
          </div>
          <div id="chart-line">
            <apexchart type="area" height="130" :options="errorChecksChartOptionsLine" :series="errorChecksSeries"></apexchart>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
        elevation="2"
        class="mt-5">
      <v-card-title>
        {{ $t('pages.mods.statistics.headings.updates.fullPackage') }}
        <v-progress-circular
            v-if="isLoading === 'statistics'"
            class="ml-3"
            :size="24"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="isLoading != 'statistics'">
        <div id="wrapper">
          <div id="chart-line2">
            <apexchart type="line" height="230" :options="fullPackageChartOptions" :series="fullPackageSeries"></apexchart>
          </div>
          <div id="chart-line">
            <apexchart type="area" height="130" :options="fullPackageChartOptionsLine" :series="fullPackageSeries"></apexchart>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
        elevation="2"
        class="mt-5">
      <v-card-title>
        {{ $t('pages.mods.statistics.headings.updates.everyFile') }}
        <v-progress-circular
            v-if="isLoading === 'statistics'"
            class="ml-3"
            :size="24"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="isLoading != 'statistics'">
        <div id="wrapper">
          <div id="chart-line2">
            <apexchart type="line" height="230" :options="everyFileChartOptions" :series="everyFileSeries"></apexchart>
          </div>
          <div id="chart-line">
            <apexchart type="area" height="130" :options="everyFileChartOptionsLine" :series="everyFileSeries"></apexchart>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
        elevation="2"
        class="mt-5">
      <v-card-title>
        {{ $t('pages.mods.statistics.headings.updates.quickJump') }}
        <v-progress-circular
            v-if="isLoading === 'statistics'"
            class="ml-3"
            :size="24"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="isLoading != 'statistics'">
        <div id="wrapper">
          <div id="chart-line2">
            <apexchart type="line" height="230" :options="quickJumpChartOptions" :series="quickJumpSeries"></apexchart>
          </div>
          <div id="chart-line">
            <apexchart type="area" height="130" :options="quickJumpChartOptionsLine" :series="quickJumpSeries"></apexchart>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
        v-model="milestoneDialog"
        :persistent="isLoading === 'save'"
        width="400"
        v-if="selected != null"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t(selected.id != null ? "pages.mods.statistics.milestones.dialog.title.edit" : "pages.mods.statistics.milestones.dialog.title.create")}}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="selected.valid">
              <v-row>
                <v-text-field
                    v-model="selected.name"
                    :label="$t('pages.mods.statistics.milestones.dialog.fields.name.label')"
                    :hint="$t('pages.mods.statistics.milestones.dialog.fields.name.hint')"
                    :rules="nameRules"
                    :disabled="isLoading != null"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                    v-model="selected.date"
                    :label="$t('pages.mods.statistics.milestones.dialog.fields.date.label')"
                    :hint="$t('pages.mods.statistics.milestones.dialog.fields.date.hint')"
                    :rules="dateRules"
                    :disabled="isLoading != null"></v-text-field>
              </v-row>
            </v-form>
            <v-row justify="end" class="mt-3">
              <v-col class="text-right">
                <v-btn
                    @click="milestoneDialog = false"
                    :disabled="isLoading != null"
                    color="secondary"
                    text
                    class="mr-2">{{ $t("pages.mods.statistics.milestones.dialog.buttons.cancel") }}</v-btn>
                <v-btn
                    v-if="selected.id == null"
                    @click="saveMilestone"
                    :disabled="isLoading != null || selected.valid !== true"
                    :loading="isLoading === 'save'"
                    text
                    color="primary">{{ $t("pages.mods.statistics.milestones.dialog.buttons.create") }}</v-btn>
                <v-btn
                    v-else
                    @click="saveMilestone"
                    :disabled="isLoading != null || selected.valid !== true"
                    :loading="isLoading === 'save'"
                    text
                    color="primary">{{ $t("pages.mods.statistics.milestones.dialog.buttons.save") }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import FormatService from "@/services/FormatService";
import {AlertService} from "@/services/AlertService";
import {DialogService} from "@/services/DialogService";
import {ModsService} from "@/services/ModsService";

export default {
  name: "ModStatisticsView",
  components: {SettingsPageLayout},
  data() {
    return {
      isLoading: null,
      selected: null,

      nameRules: [
        v => !!v || this.$t('pages.mods.statistics.milestones.dialog.fields.name.errors.required')
      ],
      dateRules: [
        v => !!v || this.$t('pages.mods.statistics.milestones.dialog.fields.date.errors.required'),
        v=> FormatService.dateTimeRegex.test(v) || this.$t('pages.mods.statistics.milestones.dialog.fields.date.errors.format')
      ],

      milestones: [],

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: () => false,
          onClick: this.createMilestone,
          icon: 'mdi-timeline-plus-outline',
          text: 'pages.mods.statistics.toolbar.createMilestone'
        },
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'statistics',
          onClick: this.loadStatistics,
          icon: 'mdi-refresh',
          text: 'pages.mods.statistics.toolbar.refresh'
        }
      ],
      dateTimeFormatter: FormatService.dateTimeFormatter,

      milestoneDialog: false,

      allUpdatesSeries: [],
      allUpdatesChartOptions: {
        chart: {
          id: 'mods.updates.all',
          type: 'line',
          height: 230,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            },
            show: true
          }
        },
        theme: {
          mode: 'dark'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
            formatter: function(val){
              return FormatService.dateTimeFormatter.format(val)
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      allUpdatesChartOptionsLine: {
        chart: {
          id: 'mods.updates.all.brush',
          height: 130,
          type: 'area',
          brush:{
            target: 'mods.updates.all',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
            }
          },
        },
        theme: {
          mode: 'dark'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2
        },
        annotations: {
          xaxis: []
        }
      },


      errorChecksSeries: [],
      errorChecksChartOptions: {
        chart: {
          id: 'mods.errorChecks',
          type: 'line',
          height: 230,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            },
            show: true
          }
        },
        theme: {
          mode: 'dark'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
            formatter: function(val){
              return FormatService.dateTimeFormatter.format(val)
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      errorChecksChartOptionsLine: {
        chart: {
          id: 'mods.errorChecks.brush',
          height: 130,
          type: 'area',
          brush:{
            target: 'mods.errorChecks',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
            }
          },
        },
        theme: {
          mode: 'dark'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2
        },
        annotations: {
          xaxis: []
        }
      },


      fullPackageSeries: [],
      fullPackageChartOptions: {
        chart: {
          id: 'mods.updates.fullPackage',
          type: 'line',
          height: 230,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            },
            show: true
          }
        },
        theme: {
          mode: 'dark'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
            formatter: function(val){
              return FormatService.dateTimeFormatter.format(val)
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      fullPackageChartOptionsLine: {
        chart: {
          id: 'mods.updates.fullPackage.brush',
          height: 130,
          type: 'area',
          brush:{
            target: 'mods.updates.fullPackage',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
            }
          },
        },
        theme: {
          mode: 'dark'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2
        },
        annotations: {
          xaxis: []
        }
      },

      everyFileSeries: [],
      everyFileChartOptions: {
        chart: {
          id: 'mods.updates.everyFile',
          type: 'line',
          height: 230,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            },
            show: true
          }
        },
        theme: {
          mode: 'dark'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
            formatter: function(val){
              return FormatService.dateTimeFormatter.format(val)
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      everyFileChartOptionsLine: {
        chart: {
          id: 'mods.updates.everyFile.brush',
          height: 130,
          type: 'area',
          brush:{
            target: 'mods.updates.everyFile',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
            }
          },
        },
        theme: {
          mode: 'dark'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2
        },
        annotations: {
          xaxis: []
        }
      },

      quickJumpSeries: [],
      quickJumpChartOptions: {
        chart: {
          id: 'mods.updates.quickJump',
          type: 'line',
          height: 230,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            },
            show: true
          }
        },
        theme: {
          mode: 'dark'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
            formatter: function(val){
              return FormatService.dateTimeFormatter.format(val)
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      quickJumpChartOptionsLine: {
        chart: {
          id: 'mods.updates.quickJump.brush',
          height: 130,
          type: 'area',
          brush:{
            target: 'mods.updates.quickJump',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
            }
          },
        },
        theme: {
          mode: 'dark'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd.MM.yyyy'
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2
        },
        annotations: {
          xaxis: []
        }
      },
    }
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadStatistics();
      }
    }
  },

  methods: {
    loadStatistics(){
      this.isLoading = 'statistics'
      ModsService.loadStatistics(this.$route.params.modId).then(data => {
        this.$log.info("Loaded statistics:",data)

        data.milestones = data.milestones.map(m => ({
          id: m.id,
          name: m.name,
          date: new Date(m.date)
        }))

        const annotations = data.milestones.map(m => ({
          x: m.date.getTime(),
          strokeDashArray: 0,
          borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: m.name,
          }
        }))

        this.milestones = data.milestones;


        this.allUpdatesChartOptions.annotations.xaxis = annotations;
        this.allUpdatesChartOptionsLine.annotations.xaxis = annotations;

        this.errorChecksChartOptions.annotations.xaxis = annotations;
        this.errorChecksChartOptionsLine.annotations.xaxis = annotations;

        this.fullPackageChartOptions.annotations.xaxis = annotations;
        this.fullPackageChartOptionsLine.annotations.xaxis = annotations;

        this.everyFileChartOptions.annotations.xaxis = annotations;
        this.everyFileChartOptionsLine.annotations.xaxis = annotations;

        this.quickJumpChartOptions.annotations.xaxis = annotations;
        this.quickJumpChartOptionsLine.annotations.xaxis = annotations;


        this.allUpdatesSeries = data.statistics.allUpdates;
        this.errorChecksSeries = data.statistics.errorChecks;
        this.fullPackageSeries = data.statistics.fullPackage;
        this.everyFileSeries = data.statistics.everyFile;
        this.quickJumpSeries = data.statistics.quickJump;
        this.isLoading = null;
      }).catch(err => {
        this.$log.error("Failed to load statistics:",err)
        AlertService.displayAlert('error', 'pages.mods.statistics.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    createMilestone(){
      this.$log.info("Create milestone:")
      this.selected = {
        name: '',
        date: this.dateTimeFormatter.format(new Date())
      };
      this.milestoneDialog = true;
    },
    editMilestone(milestone){
      this.$log.info("Edit milestone:",milestone.id)
      this.selected = {
        id: milestone.id,
        name: milestone.name,
        date: this.dateTimeFormatter.format(milestone.date)
      };
      this.milestoneDialog = true;
    },
    saveMilestone(){
      const result = FormatService.dateTimeRegex.exec(this.selected.date)
      const numbers = result.slice(1, 7).map(v => Number.parseInt(v));
      const date = new Date(numbers[2], numbers[1] - 1, numbers[0], numbers[3], numbers[4], numbers[5])

      const isEdit = this.selected.id != null;

      this.isLoading = 'save'
      AlertService.clearAlert();

      const data = {
        name: this.selected.name,
        date
      }

      const promise = isEdit
          ? ModsService.setMilestone(this.$route.params.modId, this.selected.id, data)
          : ModsService.createMilestone(this.$route.params.modId, data);

      promise.then(() => {
        if(this.isEdit) {
          this.$log.info('Saved milestone')
          AlertService.displayAlert('success', 'pages.mods.statistics.milestones.messages.saved', 2000)
        }else{
          this.$log.info('Created milestone')
          AlertService.displayAlert('success', 'pages.mods.statistics.milestones.messages.created', 2000)
        }
        this.milestoneDialog = false;
        this.loadStatistics();
      }).catch(err => {
        this.$log.error(err)

        if(isEdit) {
          AlertService.displayAlert('error', 'pages.mods.statistics.milestones.messages.failedToSave', 5000)
        }else{
          AlertService.displayAlert('error', 'pages.mods.statistics.milestones.messages.failedToCreate', 5000)
        }

        this.isLoading = null;
      })
    },
    deleteMilestone(milestone){
      this.$log.info("Delete milestone:",milestone.id)
      DialogService.addDialog({
        title: 'pages.mods.statistics.milestones.deleteDialog.title',
        text: 'pages.mods.statistics.milestones.deleteDialog.text',
        width: 400,
        buttons: [
          {
            text: 'pages.mods.statistics.milestones.deleteDialog.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'pages.mods.statistics.milestones.deleteDialog.buttons.delete',
            color: 'primary'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.isLoading = 'delete';
        this.selected = milestone;
        AlertService.clearAlert();
        ModsService.deleteMilestone(this.$route.params.modId, milestone.id).then(() => {
          this.loadStatistics()
        }).catch(() => {
          AlertService.displayAlert('error', 'pages.mods.statistics.milestones.messages.failedToDelete')
          this.isLoading = null
        })
      })
    }
  },

  mounted() {
    this.loadStatistics();
  }
}
</script>

<style scoped>

</style>
