const sizeUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export default class FormatService {
    static dateTimeRegex = new RegExp('^(\\d{1,2}).(\\d{1,2}).(\\d\\d\\d\\d), (\\d{1,2}):(\\d\\d):(\\d\\d)$')

    static dateTimeFormatter = new Intl.DateTimeFormat('de-DE', {
        second: "2-digit",
        minute: "2-digit",
        hour: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    })
    static dateFormatter = new Intl.DateTimeFormat('de-DE', {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    })

    static numberFormatter = new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 2
    })

    static formatSpace(space){
        let l = 0, n = space;
        while(n >= 1024 && ++l){
            n = n/1024;
        }

        return `${this.numberFormatter.format(n)} ${l < sizeUnits.length ? sizeUnits[l] : '??'}`
    }

    static getVersionNameTime(){
        var d = new Date()
        d.setSeconds(0)
        return this.dateTimeFormatter.format(d)
    }
}
