<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
  export default {
  name: 'App'
};
</script>



<style>
html {
  overflow-y: auto !important;
}
</style>
