<template>
  <div>
    <v-toolbar>
      <slot name="toolbar-title">
        <v-toolbar-title>{{ (translateToolbarTitle ? $t(toolbarTitle) : toolbarTitle) }}</v-toolbar-title>
      </slot>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-for="(data, index) in toolbarButtons" :key="index">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              :disabled="data.isDisabled(isLoading) === true"
              :loading="data.isLoading(isLoading) === true"
              @click="data.onClick"
              v-on="on"
              v-bind="attrs">
            <v-icon>{{ data.icon }}</v-icon>
          </v-btn>
        </template>

        <span>{{ $t(data.text) }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear
        indeterminate
        color="primary"
        :active="progressBarLoading === true"
    ></v-progress-linear>
    <v-container>
      <v-row justify="center">
        <v-col
            cols="12"
            sm="12"
            md="10"
            lg="8">
          <transition-group
                      type="transition"
                      mode="in-out"

                      enter-class="slide-y-transition-enter"
                      enter-to-class="slide-y-transition-enter-to"
                      enter-active-class="slide-y-transition-enter-active"

                      leave-class="slide-y-transition-leave"
                      leave-to-class="slide-y-transition-leave-to"
                      leave-active-class="slide-y-transition-leave-active"

                      appear>
            <v-alert
                v-for="(item, index) in alerts"
                :key="item.id"
                v-show="item.show"
                @input="item.show = false"
                text
                :type="item.type"
                @transitionend="transitionEnd(item, index, item.id)"
            >
              {{$t(item.message)}}
              <template v-slot:append>
                <v-btn small icon @click="item.show = false">
                  <v-icon :color="item.type">mdi-close-circle</v-icon>
                </v-btn>
              </template>
            </v-alert>
          </transition-group>
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {ClearAlertEvent, DisplayAlertEvent} from "@/services/AlertService";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "SettingsPageLayout",

  props: {
    isLoading: {},
    toolbarTitle: {},
    progressBarLoading: {},
    toolbarButtons: {},
    translateToolbarTitle: {
      default: true
    }
  },

  data(){
    return {
      alerts: []
      /* {
        show: false,
        type: 'error',
        message: '',
        timeoutId: null
      }*/
    }
  },

  methods: {
    displayAlert(data){

      data.id = uuidv4();

      this.alerts.push(data);

      data.show = true

      if(data.timeout > 0){
        this.$log.debug('Initializing timeout:',data, data.timeout)
        data.timeoutId = setTimeout(() => {
          data.show = false
          this.$log.debug('Timeout reached:', data)
          this.$forceUpdate();
        }, data.timeout);
      }
    },
    clearAlert(){
      /*if(this.alertData.timeoutId != null) {
        clearTimeout(this.alertData.timeoutId);
        this.alertData.timeoutId = null;
      }

      this.alertData.show = false*/
    },
    transitionEnd(alert, index, id){
      if(alert.show !== false) return;
      if(this.alerts.length <= index) return;
      if(this.alerts[index].id !== id) return;

      if(alert.timeoutId != null){
        clearTimeout(alert.timeoutId)
      }

      this.alerts.splice(index, 1)
    }
  },

  mounted() {
    this.$eventBus.$on(DisplayAlertEvent, this.displayAlert);
    this.$eventBus.$on(ClearAlertEvent, this.clearAlert);
  },

  destroyed() {
    this.$eventBus.$off(DisplayAlertEvent, this.displayAlert);
    this.$eventBus.$off(ClearAlertEvent, this.clearAlert);
  }
}
</script>

<style scoped>

</style>