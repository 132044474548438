import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from "@/components/MainLayout";
import LauncherVersionsView from "@/views/launcher/LauncherVersionsView";
import LauncherImagesView from "@/views/launcher/LauncherImagesView";
import LauncherBranchesView from "@/views/launcher/LauncherBranchesView";
import LauncherStatisticsView from "@/views/launcher/LauncherStatisticsView";
import ModVersionsView from "@/views/mod/ModVersionsView";
import ModImagesView from "@/views/mod/ModImagesView";
import ModBranchesView from "@/views/mod/ModBranchesView";
import ModStatisticsView from "@/views/mod/ModStatisticsView";
import ModSettingsView from "@/views/mod/ModSettingsView";
import ModAdminView from "@/views/mod/ModAdminView";
import ProgressOverviewView from "@/views/ProgressOverviewView";
import ProgressView from "@/views/ProgressView";
import LauncherBranchView from "@/views/launcher/LauncherBranchView";
import LauncherVersionView from "@/views/launcher/LauncherVersionView";
import ModBranchView from "@/views/mod/ModBranchView";
import ModVersionView from "@/views/mod/ModVersionView";
import ModCreateView from "@/views/mod/ModCreateView";
import ModButtonView from "@/views/mod/ModButtonView";
import ModButtonsView from "@/views/mod/ModButtonsView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/launcher/versions',
        component: LauncherVersionsView
      },
      {
        path: '/launcher/versions/create',
        component: LauncherVersionView
      },
      {
        path: '/launcher/versions/edit/:versionId',
        component: LauncherVersionView
      },
      {
        path: '/launcher/images',
        component: LauncherImagesView
      },
      {
        path: '/launcher/branches',
        component: LauncherBranchesView
      },
      {
        path: '/launcher/branches/create',
        component: LauncherBranchView
      },
      {
        path: '/launcher/branches/edit/:branchId',
        component: LauncherBranchView
      },
      {
        path: '/launcher/statistics',
        component: LauncherStatisticsView
      },
      {
        path: '/mods/create',
        component: ModCreateView
      },
      {
        path: '/mods/:modId/versions',
        component: ModVersionsView
      },
      {
        path: '/mods/:modId/versions/create',
        component: ModVersionView
      },
      {
        path: '/mods/:modId/versions/edit/:versionId',
        component: ModVersionView
      },
      {
        path: '/mods/:modId/images',
        component: ModImagesView
      },
      {
        path: '/mods/:modId/buttons',
        component: ModButtonsView
      },
      {
        path: '/mods/:modId/buttons/create',
        component: ModButtonView
      },
      {
        path: '/mods/:modId/buttons/edit/:buttonId',
        component: ModButtonView
      },
      {
        path: '/mods/:modId/branches',
        component: ModBranchesView
      },
      {
        path: '/mods/:modId/branches/create',
        component: ModBranchView
      },
      {
        path: '/mods/:modId/branches/edit/:branchId',
        component: ModBranchView
      },
      {
        path: '/mods/:modId/statistics',
        component: ModStatisticsView
      },
      {
        path: '/mods/:modId/settings',
        component: ModSettingsView
      },
      {
        path: '/mods/:modId/admin',
        component: ModAdminView
      },
      {
        path: '/progress',
        component: ProgressOverviewView
      },
      {
        path: '/progress/:progressId',
        component: ProgressView
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
