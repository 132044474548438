import Keycloak from "keycloak-js";
import Vue from "vue";

const initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'login-required'
}

export class KeycloakService {

    static KeycloakInstance = Keycloak(initOptions);

    static initKeycloak(){
        return new Promise((resolve, reject) => {
            this.KeycloakInstance.init({ onLoad: initOptions.onLoad, checkLoginIframe: false }).then((auth) => {
                if (!auth) {
                    window.location.reload();
                    return reject();
                }
                Vue.$log.info("Authenticated");

                //Token Refresh
                setInterval(() => {
                    this.KeycloakInstance.updateToken(70).then((refreshed) => {
                        if (refreshed) {
                            Vue.$log.info('Token refreshed');
                        } else {
                            Vue.$log.warn('Token not refreshed, valid for '
                                + Math.round(this.KeycloakInstance.tokenParsed.exp + this.KeycloakInstance.timeSkew - new Date().getTime() / 1000) + ' seconds');
                        }
                    }).catch(() => {
                        Vue.$log.error('Failed to refresh token');
                    });
                }, 6000)

                return resolve();

            }).catch(() => {
                Vue.$log.error("Authenticated Failed");
                return reject();
            });
        });
    }

    static getAxiosConfig(config){
        config ??= {};
        config.headers ??= {};

        config.headers["Authorization"] = `Bearer ${this.KeycloakInstance.token}`;

        return config;
    }
}
