import Vue from 'vue'
import Vuex from 'vuex'
import websocketModule from "@/store/modules/websocketModule";
import dialogModule from "@/store/modules/dialogModule";
import modsModule from "@/store/modules/modsModule";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    websocketModule,
    dialogModule,
    modsModule
  }
})
