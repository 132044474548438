<template>
  <settings-page-layout
      :is-loading="isLoading"
      toolbar-title="pages.mods.buttons.title"
      :progress-bar-loading="isLoading === 'buttons'"
      :toolbar-buttons="toolbarButtons">
    <v-card
        elevation="2"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" style="width:50%">
              {{ $t('pages.mods.buttons.headers.linkUrl') }}
            </th>
            <th class="text-left">
              {{ $t('pages.mods.buttons.headers.imageUrl') }}
            </th>
            <th class="text-right">
              {{ $t('pages.mods.buttons.headers.actions') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-ripple
              v-for="(item, index) in buttons"
              :key="index"
              @click.prevent="editButton(item.id)"
              style="cursor: pointer;user-select: none"
          >
            <td>{{ item.linkUrl }}</td>
            <td>{{ item.imageUrl}}</td>
            <td class="align-end">
              <v-row align="end">
                <v-col class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop="editButton(item.id)"
                          :disabled="isLoading != null"
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-cog
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.buttons.buttons.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          @click.stop="deleteButton(item.id)"
                          :disabled="isLoading != null"
                          :loading="isLoading === 'delete' && selected === item.id"
                          v-on="on"
                          v-bind="attrs">
                        <v-icon
                            small>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('pages.mods.buttons.buttons.delete') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from "@/components/SettingsPageLayout";
import {AlertService} from "@/services/AlertService";
import {ModsService} from "@/services/ModsService";
import {DialogService} from "@/services/DialogService";

export default {
  name: "ModButtonsView",
  components: {SettingsPageLayout},
  data() {
    return {
      isLoading: null,
      selected: null,

      toolbarButtons: [
        {
          isDisabled: l => l != null,
          isLoading: () => false,
          onClick: this.createButton,
          icon: 'mdi-shape-rectangle-plus',
          text: 'pages.mods.buttons.toolbar.createButton'
        },
        {
          isDisabled: l => l != null,
          isLoading: l => l === 'buttons',
          onClick: this.loadButtons,
          icon: 'mdi-refresh',
          text: 'pages.mods.buttons.toolbar.refresh'
        }
      ],

      buttons: []
    }
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadButtons();
      }
    }
  },

  methods: {
    loadButtons(){
      this.isLoading = 'buttons'
      AlertService.clearAlert();
      ModsService.getButtons(this.$route.params.modId).then(buttons => {
        this.$log.info('Loaded buttons:',buttons)
        this.buttons = buttons
      }).catch(() => {
        AlertService.displayAlert('error', 'pages.mods.buttons.messages.failedToLoad', 0)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    createButton(){
      this.$router.push(`/mods/${this.$route.params.modId}/buttons/create`);
    },
    editButton(buttonId){
      if(this.isLoading != null) return;

      this.$log.debug('Edit button:',buttonId)

      this.$router.push(`/mods/${this.$route.params.modId}/buttons/edit/${buttonId}`)
    },
    deleteButton(buttonId){

      this.$log.debug('Delete button:',buttonId)
      DialogService.addDialog({
        title: 'pages.mods.buttons.deleteDialog.title',
        text: 'pages.mods.buttons.deleteDialog.text',
        width: 400,
        buttons: [
          {
            text: 'pages.mods.buttons.deleteDialog.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'pages.mods.buttons.deleteDialog.buttons.delete',
            color: 'primary'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.selected = buttonId;
        this.isLoading = 'delete';
        AlertService.clearAlert();
        ModsService.deleteButton(this.$route.params.modId, buttonId).then(() => {
          this.loadButtons()
        }).catch(() => {
          AlertService.displayAlert('error', 'pages.mods.buttons.messages.failedToDelete', 2000)
          this.isLoading = null
        })
      })
    }
  },

  mounted() {
    this.loadButtons();
  }
}
</script>

<style scoped>

</style>
